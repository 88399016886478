import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap";

export default function Login() {
  const { signup, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await signup();
      history.push("/admin");
    } catch {
      setError("Failed to log in");
    }

    setLoading(false);
  }

  if (currentUser) {
    return <Redirect to={"/admin"} />;
  } else
    return (
      <>
        <section>
          <Container>
            <Row>
              <Col sm={6} className="mx-auto pt-150">
                <Card>
                  <CardBody className="text-center">
                    <img
                      className="img-thumbnail navbar-brand-img bg-white border-0 shadow-none"
                      alt={"lumu logo"}
                      width={200}
                      src={
                        "https://lumu.io/wp-content/uploads/2020/01/lumu-color.png"
                      }
                    />
                    <h2 className="text-center my-4">
                      Welcome to <b>Lumu Growth</b>
                    </h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <form onSubmit={handleSubmit}>
                      <Button
                        disabled={loading}
                        className="w-100"
                        type="submit"
                      >
                        Enter
                      </Button>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
}
