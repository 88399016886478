import React, { useEffect, Suspense } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser, logout } = useAuth();

  useEffect(() => {
    if (currentUser) {
      if (!userEmailIsValid(currentUser.email)) {
        logout();
      }
    }
  }, [currentUser, logout]);

  function userEmailIsValid(email) {
    let companyAddress = email.split("@");
    return companyAddress[1] === "lumu.io";
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
          </Suspense>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
}
