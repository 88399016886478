import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./PrivateRoute";
import Login from "./views/auth/Login";

const AdminLayout = React.lazy(() => import("layouts/Admin.js"));

//import AdminLayout from "layouts/Admin.js";
//import AuthLayout from "layouts/Auth.js";

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <Switch>
        <PrivateRoute path="/admin" component={AdminLayout} />
        <Route path="/login" component={Login} />
        <Redirect from="*" to="/login" />
      </Switch>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

/*
<Route path="/auth" render={(props) => <AuthLayout {...props} />} />
 */
